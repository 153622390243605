/*scss/variables/_vertical-rhythm.scss*/

$base: 24px;

h1, h2, h3, h4, h5, h6, hgroup,
ul, ol, dd,
p, figure,
pre, table, hr, .atoms-code-example {
  margin-bottom: $base;
}

$navBreak: 895px;