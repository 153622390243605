/* scss/organisms/_banner.scss */

.banner {
  text-align: center;
  background: $black;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    display: block;
  }
}