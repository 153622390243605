/* scss/molecules/_navMain.scss */

.navMain {
  display: flex;
  justify-content: space-evenly;
  a {
    color: $text-color;
    font-size: 19px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.nav-trigger {
  display: none;
  font-size: 30px;
  color: $black;
  vertical-align: middle;
  margin-left: 20px;
}

@media only screen and ( max-width: 1070px ){
  .navMain {
    a {
      font-size: 16px;
    }
  }
}


@media only screen and ( min-width: 901px ){
  .navMain {
    display: flex !important;
  }

}

@media only screen and ( max-width: 900px ){
  .navMain {
    display: none;
    background: $red1;
    text-align: left;
    padding: 10px 0;
    a {
      color: #fff;
      padding: 5px 20px;
      display: block;
    }

  }
  .nav-trigger {
    display: inline-block;
  }
}