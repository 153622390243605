/* scss/organisms/_featureBlock.scss */

.featureBlock {
  padding: 25px 15px;
  background: url("../img/bg-brick.png");
  background-size: cover;
}

#featureSlider {
  max-width: 825px;
  margin: auto;
}