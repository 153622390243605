/* scss/organisms/_headerMain.scss */

.headerMain {
  background: $black;
  height: 100vh;
  background: url("../img/banner-home.png") center top no-repeat;
  background-size: cover;
  z-index: 99999;
  position: relative;
  &.headerMain-inner {
    height: 250px;
    background: url("../img/banner-clouds.png") center top no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    @media only screen and ( max-width: 900px  ){
      height: 200px;
    }
  }
  .logo {
    max-width: 385px;
    width: 100%;
  }
}

.headerMain__inner {
  max-width: 1300px;
  margin: auto;
  //background: url("../img/banner-home.png") center top no-repeat;
  //background-size: cover;
  //height: 100%;
  padding: 20px 15px;
}




.headerMain__item {
  text-align: center;
}

.headerMain__item-top {
  text-align: right;
  margin-bottom: 20px;
}

.headerMain__item-mid {
  margin-bottom: 25px;
}

@media only screen and ( max-width: 900px ){
  .headerMain__inner {
    padding: 0;
  }
  .headerMain__item-mid {
    padding: 0 15px;
  }
  .headerMain__item-top {
    padding-top: 20px;
    padding-right: 15px;
  }
}