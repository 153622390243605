/* scss/siteForms/_contactForm.scss */

.gform_wrapper{
  max-width: 590px !important;
  input[type=text], select{
    height: 45px;
    font-weight: 400 !important;
  }
}

.gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
  width: 100% !important;
}

.gform_wrapper label.gfield_label {
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
  @extend .btns;
  @extend .btns-sml;
  @extend .btn1;
  border: none;
}
.gform_wrapper.gf_browser_chrome .gform_body {
  width: 100% !important;
}
