/* scss/organisms/_footerMain.scss */

.footerMain {
  background: $black;
  padding: 40px 15px;
  color: #fff;
  text-align: center;
}

.footerMain__inner {
  max-width: 1200px;
  margin: auto;
}

.footerNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
  a {
    color: #fff;
    font-size: 19px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.addr {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
  a {
    color: $red2;
  }
  span {
    margin-bottom: 5px;
    display: inline-block;
  }
  .addr__divider {
    margin: 0 15px;
  }
  &.addr-alt {
    font-weight: 400;
  }
}

.legal {
  font-size: 10px;
  a {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and ( max-width: 475px ){
  .addr {
    span {
      display: block;
    }
    .addr__divider {
      display: none;
    }
  }
}