/* scss/layout/_sectionBlock.scss */

.sectionBlock{
  //padding:0  20px $base*4;
  padding: 50px 15px 80px;
  &:first-of-type{
    //padding-top: 50px;
    //padding-bottom: 20px;
  }
}

.sectionBlock-alt{
  padding-bottom: 0;
}

.sectionBlock__heading{
  text-align: center;
  margin-bottom: $base*2.5;
}

@media only screen and (max-width: 761px){
  .sectionBlock{
    padding:0  15px $base*2;
    &:first-of-type{
      padding-top: $base*2;
    }
  }
  .sectionBlock__heading{
    margin-bottom: $base;
  }
}
