/* scss/organisms/_linkBlock.scss */

.linkBlock {
  background: $red1;
  text-align: center;
  padding: 30px 15px;
}

.linkBlock__inner {
  max-width: 925px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.iconLink {
  display: block;
  img {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media only screen and ( max-width: 762px ){
  .linkBlock__inner {
    display: block;
  }
  .iconLink {
    margin-bottom: 40px;
  }
}
