.btns {
  display: inline-block;
  background: none;
  outline: none;
  border: none;
  padding: 18px 40px;
  color: #fff;
  background: $red2;
  opacity: 1;
  cursor: pointer;
  transition: all .25s;
  position: relative;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  @media only screen and ( max-width: 482px ){
    padding: 15px 30px;
    font-size: 20px;
    font-weight: 400;
  }
  &:hover, &:active, &:focus {
      color: #fff;
      background: $red1;
      text-decoration: none;
    }
}

.btns-sml {
  padding: 12px 25px;
  font-size: 20px;
}

.btn1 {
  background: $color1;
   &:hover, &:active, &:focus {
    background: $color1;
  }
}
.btn2 {
  background: $color4;
  color: $textColor;
   &:hover, &:active, &:focus {
    background: $color4;
    color: $textColor;
  }
}
.btn3 {
  background: $color3;
   &:hover, &:active, &:focus {
    background: $color3;
  }
}



.btnLine {
  display: inline-block;
  background: 0 0;
  padding: 11px 30px;
  color: #333;
  opacity: 1;
  cursor: pointer;
  transition: opacity .25s;
  position: relative;
  border: none;
  font-size: 16px;
  text-align: center;
  border-radius: 0;
  vertical-align: middle;
  font-weight: 400;
}

.btnLine-3 {
  color: #fff;
  background: rgba(82, 78, 76, 0.62);
}