/* scss/siteFonts/_fonts.scss */

$bodyFont: 'Arial', sans-serif;
$secondaryFont: 'Arial', sans-serif;

.font1{
	font-family: $bodyFont;
}
.font2{
	font-family: $secondaryFont;
	font-weight: 300;
}

.font{
    font-weight: 400;
	span{
		display:block;
	}
}