.h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin-top: 0;
  margin-bottom: $base;
  font-family: 'Times New Roman', serif;
  line-height: 1;
  text-transform: uppercase;
}

.h1, h1 {
  font-size: 48px;
  @media only screen and (max-width: 761px) {
    font-size: 34px;
  }
}

.h2, h2 {
  font-size: 40px;
  font-weight: 400;
  @media only screen and (max-width: 761px) {
    font-size: 34px;
  }
}

.h3, h3 {
  font-size: 30px;
  font-weight: 400;
  color: $color2;
  @media only screen and (max-width: 761px) {
    font-size: 24px;
  }
}

.h4, h4 {
  font-size: 20px;
  color: $color3;
  @media only screen and (max-width: 761px) {
    font-size: 18px;
  }
}
