/* scss/layout/_pageMain.scss */

.pageMain{
    padding: 50px 20px;
    background: url('../img/background-texture21x35.jpg');
}
.pageMain__content{
    @include wrap(1000px, 0);
}

.pageHeading{
    margin-bottom: 40px;
}

.pageMain__inner{
    @extend .wrap;
    padding: 0;
}

@media only screen and (max-width: 761px){
    .pageHeading{
        margin-bottom: 45px;
    }
}

@media only screen and (max-width: 761px){
    .pageMain{
        padding: 40px 15px;
    }
}