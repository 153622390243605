/*scss/variables/_colors.scss*/

$color1: #c15622; //PRIMARY COLOR
$color2: #524e4c;  //ACCENT COLOR
$color3: #8e8683; 
$color4: #f4f4f4;


$red1: #820024;
$red2: #d32222;
$black: #000000;
$textColor: #000000;  //BODY TEXT COLOR

.color1{
	background: $color1;
}
.color2{
	background: $color2;
}
.color3{
	background: $color3;
}
.color4{
	background: $color4;
}
.textColor{
	background: $textColor;
}



.colors{
	@extend .grid-row;
}
.color{
    width:100px;
    height:100px;
    float:left;
    margin-right:10px;
    padding:10px;
    color:#fff;
    font-size:12px;
}

.colorFixed{
  position: fixed;
  z-index: 3000;
  right: 0;
  top: 0; 
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.57); 
  .color{
    float: none; 
    margin-right:0;
    width:70px;
    height:50px;
  }
}

