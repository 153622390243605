/* scss/organisms/_formBlock.scss */

.formBlock {
  background: $black;
  padding: 35px 15px;
}

.formBlock__inner {
  max-width: 925px;
  margin: auto;
}

.formIcon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.formIcon__right {
  padding-left: 20px;
}

.formBtn {
  display: flex;
}

.formIcon__heading {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.formBtn__btn {
  width: 162px;
  padding-left: 10px;
  .btns {
    padding: 22px 40px;
  }

}

.formBtn__inputs {
  width: calc(100% - 162px);
  .formGroup {
    margin-bottom: 10px;
  }
}

@media only screen and ( max-width: 690px ){
  .formIcon {
    display: block;
    text-align: center;
  }
  .formIcon__left {
    margin-bottom: 40px;
  }
  .formIcon__right {
    padding: 0;
  }
  .formBtn {
    display: block;
  }
  .formBtn__inputs, .formBtn__btn {
    width: 100%;
  }
  .formBtn__inputs {
    margin-bottom: 20px;
  }
}