/* scss/organisms/_contentBlock.scss */

.contentBlock {
  background: url("../img/bg-brick.png");
  background-size: cover;
  color: #fff;
  padding: 50px 15px;
  p {
    font-size: 22px;
  }
  .h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    color: #fff;
    //color: #820024;
    font-weight: bold;
  }
  .formHint {
    color: red;
    font-size: 16px;
  }
  .form-input {
    color: $textColor;
    font-size: 16px;
  }
}




.contentBlock__inner {
  max-width: 1120px;
  margin: auto;
}

.contentBlock__intro {
  display: flex;
}

.titleBox {
  margin-left: 40px;
  width: 300px;
  flex: 1 0 300px;
  background: #820024;
  padding: 20px;

}

.titleBox__title {
  margin-bottom: 15px;
}

.titleBox__list {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}




.content__imgs {
  margin-bottom: 20px;
  img {
    max-width: 200px;
    width: 100%;
  }
  a {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}


.formRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  @media only screen and ( max-width: 674px ){
    display: block;
  }
}


.formRow__item {
  flex: 1 0 48%;
  max-width: 48%;
  @media only screen and ( max-width: 674px ){
    max-width: 100%;
  }
}



.formGroup-sml {
  max-width: 200px;
}



.section-title {
  flex: 1 0 100%;
}




.form-base {
  margin-bottom: 40px;
  input, select {
    @extend .form-input;
  }
}


.form-section {
  margin-bottom: 40px;
  .h3 {
    color: $red2;
  }
}

.field-required {
  color: $red2;
  font-size: 24px;
}

.section-title {
  border-left: 5px solid $red2;
  padding-left: 5px;
}


@media only screen and ( max-width: 742px ){
  .contentBlock__intro {
    display: block;
    margin-bottom: 40px;
  }
  .titleBox {
    margin-left: 0;
    width: 300px;
  }
}

@media only screen and ( max-width: 599px ){
  .formRow {
    display: block;
  }
  .formRow__item {
    flex: 1 0 100%;
    max-width: 100%;
  }
}