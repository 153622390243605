.main {
  padding: 40px 15px;
  .main__inner {
    @extend .grid-row;
    max-width: 900px;
    margin: auto;
  }
}

.main__content {
  padding: $base;
}

.main__aside {
  padding: $base;
}

.main-2col {
  .main__content {
    @include col($eight);
  }
  .main__aside {
    @include col($four);

  }
}

.main-2col.main-right {
  .main__content {
    @include col($eight);
    float: right;
  }
  .main__aside {
    @include col($four);
    float: right;
  }
}



.blogBox{
  border-bottom: 2px solid #cecbca;
  margin-bottom: $base;
  padding-bottom: $base;
  .blogImg{
    padding-right: 20px;
  }
}

.blogBox__title{
  margin-bottom: $base/3;
  a{
    color: #58585a;
  }

}

.blogBox__link{
  color: $color1;
  &:hover{
    color: $color1;
  }
}

a:hover{
  color: inherit;
}


.blogImg{
  margin-bottom: $base;
  img{
    height: auto !important;
  }
}



.blogMeta{
  font-size: 14px;
  font-style: italic;
  color: #9c9c9c;
}


@media only screen and ( max-width: 620px ){
  .main {
    padding: 0;
  }
  .blogRow{
    .blogImg{
      padding-right: 0;
    }
    .nb-3, .nb-9{
      width: 100%;
    }
  }
}










