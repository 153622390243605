/* scss/organisms/_callOut.scss */

.callOut {
  background: $red1;
  padding: 30px 15px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  h1 {
    margin-bottom: 0;
  }
}

.callOut__inner {
  max-width: 980px;
  margin: auto;
}