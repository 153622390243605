/* scss/organisms/_imgRow.scss */

.imgRow {
  margin-top: 50px;
}

.imgRow__inner {
  display: flex;
  justify-content: space-around;
}
.imgRow__inner {
  max-width: 1120px;
  margin: auto;
}

.imgRow__item {
  padding: 0 10px;
}