/* scss/misc/_cleanup.scss */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  color: $textColor;
  font-size: 18px;
  @media only screen and (max-width: 761px){
    font-size: 16px;
  }
}
img{
  max-width:100%;
}
hr{
  border:1px solid #ccc;
}

a:hover, a:focus{
  text-decoration: none;
}

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


/* scss/molecules/_angleHeading.scss */

.angleHeading{
  background: #524e4c;
  position: relative;
  overflow: hidden;
  //padding: $base*2 20px;
  padding: 40px 20px;
}

.angleHeading:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 70%;
  right: 0;
  background: #65605e;
  -webkit-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.angleHeading__content{
  position: relative;
  z-index: 2;
}

.angleHeading__heading{
  text-align: center;
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
}

.u-block{
  display: block !important;
  width: 100% !important;
  max-width: 100%;
}


@media only screen and ( max-width: 1454px ){
  .angleHeading:before {
    left: 80%;
  }
}

@media only screen and ( max-width: 940px ){
  .angleHeading:before {
    left: 90%;
  }
}

@media only screen and ( max-width: 548px ){
  .angleHeading:before {
    display: none;
  }
}




@media only screen and ( max-width: 751px ){
  .angleHeading__heading{
    font-size: 28px;
  }
  .angleHeading{
    padding: $base*1.5 15px;
  }
}

.blogBox__title a{
  font-weight: 300 !important;
}



