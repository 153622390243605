/* scss/organisms/_yelpBlock.scss */

.yelpBlock {
  background: $black;
  padding: 30px 15px;
  text-align: center;
  color: #fff;
}

.yelpBlock__inner {
  max-width: 925px;
  margin: auto;
}